<template>
  <div class="sign-contract-modal">
    <HMModalHeader
      :title="$t('CONTRACTS.TITLES.SIGN_CONTRACT')"
      :closable="false"
    />
    <div class="container mt-4">
      <div
        class="d-flex flex-row justify-content-start flex-wrap flex-md-nowrap"
      >
        <StudioProductCard
          v-if="!!signedContract.product"
          :product="signedContract.product"
          :animated="false"
          :static="true"
          size="sm"
          style="pointer-events: none; margin-right: 4rem"
        />
        <div>
          <h4 class="mb-5">
            {{ $t('CONTRACTS.TEXT.REVIEW_AND_SIGN_THE_CONTRACT') }}
          </h4>
          <SignContractComponent
            class="flex-grow-1"
            :signedContract="signedContract"
            @onContractSigned="$attrs._close()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignContractComponent from './SignContractComponent';
import StudioProductCard from '@/components/products/StudioProductCard';

export default {
  name: 'SignContractModal',
  components: {
    SignContractComponent,
    StudioProductCard,
  },
  props: {
    signedContract: {
      type: Object,
    },
  },
};
</script>
